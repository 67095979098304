<template>
  <div id="account">
    <template v-for="item in dataInput">
      <textInput class="m32" v-if="!item.other">
        <field
          :border="false"
          v-model="item.val"
          :label="item.title"
          :placeholder="item.placeholder"
          class="flex-container"
          rule
          :type="item.type"
          :maxlength="item.len"
        />
      </textInput>

      <textInput class="m32 flex-container" v-else>
        <div class="t80 __title">{{ item.title }}</div>
        <b @click="chooeseAdd" class="flex1" v-if="item.val.length == 0">{{
          item.placeholder
        }}</b>
        <p @click="chooeseAdd" v-else>{{ item.val }}</p>
      </textInput>
    </template>

    <textInput class="m32 flex-container">
      <div class="flex1 t80">设置为默认地址</div>
      <van-switch v-model="checked" size="20px" active-color="#FF4848" />
    </textInput>

    <!-- 地址选择 -->
    <van-popup v-model="addChooeseBoo" round position="bottom">
      <!-- <van-loading color="#1989fa"  :vertical='true' class='login_center' v-if='columns.length == 0'/> -->
      <van-picker
        :loading="loading"
        show-toolbar
        :columns="columns"
        @cancel="addChooeseBoo = false"
        @confirm="onConfirm"
      >
      </van-picker>
    </van-popup>

    <!-- <div class="btn" @click="saveAddress()">保存使用</div> -->
    <div class="return_btn flex-container">
      <div @click="saveAddress()">
        保存
      </div>
      <div @click="$emit('update:boo', false)">
        返回
      </div>
    </div>
    <!-- <div class="btn btn2" @click="getWechat" v-if="isWechat">使用微信地址</div> -->
  </div>
</template>

<script>
import noData from "@/components/noData/";
import { getDevice } from "@get/util";
import textInput from "@/components/textInput/index.vue";
import { Field, Dialog, Toast, Switch, Popup, Picker, Loading } from "vant";
import { post } from "@get/http";
// import { getWechatShare } from "../../../../api/common";

export default {
  data: () => ({
    checked: true,
    addChooeseBoo: false,
    columns: [],
    isWechat: getDevice().isWechat,
    loading: true,
    addAddId: {},
    dataInput: [
      {
        title: "收货人",
        val: "",
        placeholder: "请填写收件人姓名",
        type: "text",
        len: "10",
      },
      {
        title: "手机号",
        val: "",
        placeholder: "收货人手机号",
        type: "digit",
        len: "11",
      },
      {
        title: "所在地区",
        val: "",
        placeholder: "省市区县 乡镇等",
        type: "text",
        other: true,
      },
      {
        title: "详细地址",
        val: "",
        placeholder: "楼道 楼牌号等",
        type: "text",
        len: "40",
      },
    ],
  }),
  props: {
    address_list: {
      default: {},
      type: Object,
    },
  },
  components: {
    noData,
    Toast,
    textInput,
    Field,
    vanSwitch: Switch,
    vanPopup: Popup,
    vanPicker: Picker,
    vanLoading: Loading,
  },
  methods: {
    callClost() {
      this.addChooeseBoo = false;
    },
    onConfirm(item, index) {
      this.addChooeseBoo = false;

      this.dataInput[2].val = item[0] + " " + item[1] + " " + item[2];
      this.addAddId = {
        Province: this.columns[index[0]].area_id,
        city: this.columns[index[0]].children[index[1]].area_id,
        area: this.columns[index[0]].children[index[1]].children[index[2]]
          .area_id,
      };

      // 获取地址联动数据
    },
    async chooeseAdd() {
      this.addChooeseBoo = true;
      // 选择地址
      if (this.columns.length != 0) {
        this.loading = false;
        return false;
      }
      let data = await post("/Area/area_app");
      let res = data.area_list,
        chiled = [];

      for (var i = 0; i < res.length; i++) {
        chiled.push({
          area_id: res[i].area_id,
          text: res[i].area_name,
          children: [],
        });
        for (var j = 0; j < res[i].child.length; j++) {
          chiled[i].children.push({
            area_id: res[i].child[j].area_id,
            text: res[i].child[j].area_name,
            children: [],
          });

          for (var z = 0; z < res[i].child[j].child.length; z++) {
            chiled[i].children[j].children.push({
              area_id: res[i].child[j].child[z].area_id,
              text: res[i].child[j].child[z].area_name,
            });
          }
        }
      }
      this.columns = chiled;
      this.loading = false;
    },
    setData() {
      if (this.address_list?.address_realname) {
        this.dataInput[0].val = this.address_list.address_realname;
        this.dataInput[1].val = this.address_list.address_mob_phone;
        this.dataInput[2].val = this.address_list.area_info;
        this.dataInput[3].val = this.address_list.address_detail;
        this.checked = this.address_list.address_is_default == 1 ? true : false;
      } else {
        for (var i = 0; i < this.dataInput.length; i++) {
          this.dataInput[i].val = "";
          this.checked = true;
        }
      }
    },
    getWechat() {
      let that = this;
      getWechatShare(encodeURIComponent(window.location.href))
        .then((res) => {
          wx.config({
            debug: false,
            appId: res.result.signPackage.appId,
            timestamp: res.result.signPackage.timestamp,
            nonceStr: res.result.signPackage.nonceStr,
            signature: res.result.signPackage.signature,
            jsApiList: [
              // 所有要调用的 API 都要加到这个列表中
              "openAddress",
              "checkJsApi",
            ],
          });

          // 分享默认自定义配置  2020-7-20 大蕉    start
          wx.ready(function() {
            wx.openAddress({
              success: function(res) {
                that.dataInput[0].val = res.userName; // 收货人姓名
                that.dataInput[3].val = res.detailInfo; // 详细收货地址信息
                that.dataInput[1].val = res.telNumber; // 收货人手机号码
              },
            });
          });
          wx.error(function(res) {});
        })
        .catch(function(error) {
          console.error(error.message);
        });
    },
    async saveAddress() {
      Toast.loading({
        message: "保存中...",
        loadingType: "spinner",
        overlay: true,
        forbidClick: true,
        duration: 0,
      });
      if (this.address_list?.address_realname) {
        // 编辑

        let data = await post(
          "/lw.Memberaddress/address_edit",

          {
            data: {
              address_id: this.address_list.address_id,
              address_realname: this.dataInput[0].val,
              city_id: this.address_list.city_id,
              area_id: this.address_list.area_id,
              area_info: this.dataInput[2].val,
              address_detail: this.dataInput[3].val,
              address_mob_phone: this.dataInput[1].val,
              address_is_default: this.checked,
              address_longitude: 0,
              address_latitude: 0,
            },
          }
        );
        this.$emit("saveAddress");
      } else {
        // 新增
        let data = await post("/lw.Memberaddress/address_add", {
          data: {
            address_realname: this.dataInput[0].val,
            city_id: this.addAddId.city,
            area_id: this.addAddId.area,
            area_info: this.dataInput[2].val,
            address_detail: this.dataInput[3].val,
            address_mob_phone: this.dataInput[1].val,
            address_is_default: this.checked,
            address_longitude: 0,
            address_latitude: 0,
          },
        });

        this.$emit("saveAddress");
      }
      Toast("十九匠商城通知：保存成功");
    },
  },
  watch: {
    address_list() {
      this.setData();
    },
  },
  created() {
    this.setData();
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

#account {
  height: 100%;
  // padding-top: rem(56);
  background: #fff;
  .btn {
    position: fixed;
    bottom: rem(32);
    left: 0;
    right: 0;
    margin: 0 auto;
    width: rem(640);
    height: rem(88);
    background: #ffe5e5;
    border-radius: rem(44);
    // font-size: rem(32);
    font-size: rem(32);
    font-weight: bold;
    // font-family: 'pfb';
    color: #ff4747;
    text-align: center;
    line-height: rem(88);
    z-index: 101;
  }
  .btn2 {
    bottom: rem(150);
    background: green;
    color: #fff;
  }
}

.m32 {
  margin: 0 rem(32);
}

.t80 {
  font-size: rem(26);

  color: #808080;
}

.login_center {
  position: absolute;
  display: inline-block;
  width: 30px;
  height: 30px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 20004;
}

.return_btn {
  position: fixed;
  bottom: rem(32);
  left: 0;
  right: 0;
  margin: 0 auto;
  width: rem(640);
  height: rem(88);
  background: #ffe5e5;
  border-radius: rem(44);
  font-size: rem(32);

  color: #ff4747;
  text-align: center;
  line-height: rem(88);
  z-index: 100;
  div {
    width: 50%;
    &:nth-of-type(2) {
      background: #ebebeb;
      color: #343434;
      border-radius: 0 rem(44) rem(44) 0;
    }
  }
}
</style>
