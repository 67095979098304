<template>
  <div id="account">
    <!-- <no-data></no-data> -->
    <van-swipe-cell
      v-for="(item, index) in address_list"
      :key="item.address_id"
    >
      <div class="address_li flex-container">
        <img
          src="@image/order/address_yes.png"
          class="chooesed"
          v-if="item.address_is_default == 1"
        />
        <div class="data flex1" @click="defaultAdd(item)">
          <div class="info flex-container">
            <p>{{ item.address_realname }}</p>
            <p>{{ item.address_mob_phone }}</p>
            <span v-if="item.address_is_default == 1">默认</span>
          </div>
          <div class="add">
            <p>{{ item.area_info }} {{ item.address_detail }}</p>
          </div>
        </div>
        <img
          src="@image/order/address_edit.png"
          class="edit"
          @click="edit(item)"
        />
      </div>
      <template #right>
        <div
          class="address_del flex-container"
          @click="onDelete(item.address_id, index)"
        >
          删除
        </div>
      </template>
    </van-swipe-cell>
    <div class="bold" v-if="address_list && address_list.length != 0"></div>
    <div class="btn" @click="edit({})" v-if="buyorder == false">
      新建收货地址
    </div>

    <div class="return_btn flex-container" v-if="buyorder">
      <div @click="edit({})">
        新建
      </div>
      <div @click="closePopup">
        返回
      </div>
    </div>

    <van-popup
      v-model="addBoo"
      round
      position="bottom"
      get-container="body"
      :overlay="true"
      :style="{ height: '90%' }"
    >
      <addAddress
        :boo.sync="addBoo"
        :address_list="props_list"
        @saveAddress="saveAddress()"
      ></addAddress>
    </van-popup>
  </div>
</template>

<script>
import noData from "@/components/noData/";
import { post } from "@get/http";
import { Dialog, Toast, Popup, SwipeCell } from "vant";
import textInput from "@/components/textInput/index.vue";
import addAddress from "./add.vue";
import { getAddressList } from "./index.js";
export default {
  name: "",
  components: {
    noData,
    addAddress,
    Toast,
    textInput,
    vanPopup: Popup,
    vanSwipeCell: SwipeCell,
  },
  props: {
    buyorder: {
      default: false,
    },
  },
  created() {
    Toast.loading({
      message: "加载中...",
      loadingType: "spinner",
      overlay: true,
      forbidClick: true,
      duration: 0,
    });
    this.getAddressList();
    Toast.clear();
  },
  methods: {
    edit(data) {
      this.addBoo = true;
      this.props_list = data;
    },
    async onDelete(address_id, index) {
      Dialog.confirm({
        title: "十九匠商城通知",
        message: "确定删除这个地址吗？",
      }).then(async () => {
        Toast.loading({
          message: "删除中...",
          loadingType: "spinner",
          overlay: true,
          forbidClick: true,
          duration: 0,
        });
        await post("/lw.Memberaddress/address_del", {
          data: {
            address_id,
          },
        });
        Toast("十九匠商城通知：删除成功");

        this.address_list.splice(index, 1);

        if (this.address_list?.length == 0) {
          this.$emit("update:info", {});
          this.$emit("close", false);
        }

        // this.getAddressList();
      });
    },
    closePopup(val) {
      this.$emit("close", val);
    },
    async defaultAdd(item) {
      // 默认地址
      Dialog.confirm({
        title: "十九匠商城通知",
        message: "确定要设为默认地址吗？",
      }).then(async () => {
        Toast.loading({
          message: "设置中...",
          loadingType: "spinner",
          overlay: true,
          forbidClick: true,
          duration: 0,
        });

        for (var i = 0; i < this.address_list.length; i++) {
          this.address_list[i].address_is_default = "0";
        }
        item.address_is_default = "1";
        // console.log(this.address_list);

        await post(
          "/lw.Memberaddress/address_edit",

          {
            data: {
              ...item,
            },
          }
        );
        Toast("十九匠商城通知：设置默认地址成功");
        if (this.buyorder) {
          this.$emit("update:info", item);
          this.$emit("close", false);
        }
      });
    },
    callClost() {},
    saveAddress() {
      this.addBoo = false;
      this.getAddressList("add");
    },
    async getAddressList(type) {
      // 获取地址列表
      let result = await getAddressList();

      this.address_list = result.address_list;
      if (type == "remove" && this.address_list?.length == 0) {
        this.$emit("update:info", {});
        this.$emit("close", false);
      }

      if (type == "add" && this.buyorder) {
        for (let index = 0; index < this.address_list.length; index++) {
          if (this.address_list[index]?.address_is_default == 1) {
            this.$emit("update:info", this.address_list[index]);
            this.$emit("close", false);
            break;
          }
        }
      }
    },
  },
  data: () => ({
    addBoo: false,
    areaList: [],
    searchResult: [],
    address_list: false, //地址列表
    props_list: {},
  }),
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

#account {
  .bold {
    height: rem(160);
  }
  height: 100%;
  // padding-bottom: rem(240);

  background: #fff;
  .btn {
    position: fixed;
    bottom: rem(32);
    left: 0;
    right: 0;
    margin: 0 auto;
    width: rem(640);
    height: rem(88);
    background: #ffe5e5;
    border-radius: rem(44);
    font-size: rem(32);

    color: #ff4747;
    text-align: center;
    line-height: rem(88);
    z-index: 100;
  }

  .return_btn {
    position: fixed;
    bottom: rem(32);
    left: 0;
    right: 0;
    margin: 0 auto;
    width: rem(640);
    height: rem(88);
    background: #ffe5e5;
    border-radius: rem(44);
    font-size: rem(32);

    color: #ff4747;
    text-align: center;
    line-height: rem(88);
    z-index: 100;
    div {
      width: 50%;
      &:nth-of-type(2) {
        background: #ebebeb;
        color: #343434;
        border-radius: 0 rem(44) rem(44) 0;
      }
    }
  }
}
.van-swipe-cell {
}
.address_li {
  align-items: center;
  margin: 0 rem(24);
  padding: rem(40) 0;
  border-bottom: rem(1) solid #ebebeb;

  .chooesed {
    width: rem(36);
    height: rem(36);
    background: #ff4848;
    border-radius: 50%;
    margin-bottom: rem(16);
    margin-right: rem(20);
  }
  .data {
    .info {
      align-items: center;
      p {
        font-size: rem(32);
        // font-weight: bold;
        color: #343434;
        &:nth-of-type(1) {
          margin-right: rem(32);
        }
      }
      span {
        display: block;
        font-size: rem(22);

        color: #ff4848;
        width: rem(60);
        height: rem(32);
        background: #ffffff;
        border: rem(1) solid #ff4848;
        border-radius: rem(4);
        text-align: center;
        margin-left: rem(16);
      }
    }
    .add {
      font-size: rem(22);

      color: #808080;
    }
  }
  .edit {
    width: rem(25);
    height: rem(25);
    margin-top: rem(20);
    margin-left: rem(40);
  }
}

.address_del {
  width: rem(120);
  height: 100%;
  background: #ff4848;
  border-radius: 0px rem(40) 0px 0px;
  color: #fff;
  font-size: rem(22);

  justify-content: center;
  align-items: center;
}

.m32 {
  margin: 0 rem(32);
}

.t80 {
  font-size: rem(26);
  color: #808080;
}
</style>
